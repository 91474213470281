
export enum RFQStatus {
    UNSPECIFIED = 0,
    REQUESTED = 1,
    QUOTED = 2,
    CANCELLED = 3,
    REJECTED = 4,
    FULFILLED = 5,
    SETTLEMENT = 6,
    PENDING = 7,
}

export enum RFQSide {
    BUY = 1,
    SELL = -1
}
