import { GetListRFQResquest, GetListRFQResponse, GetRFQRequest} from '../proto/qis/rfq/v1/rfq_api_pb';
import { RFQServicePromiseClient } from '../proto/qis/rfq/v1/rfq_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export default class RFQService {
    constructor (private rfqService: RFQServicePromiseClient = new RFQServicePromiseClient(process.env.VUE_APP_GRPC_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    async ListRFQ(startDate: Date, endDate: Date) {
        const request = new GetListRFQResquest();
        if (startDate != undefined && endDate != undefined) {
            let sDate = new Timestamp();
            let eDate = new Timestamp();
            sDate.fromDate(startDate);
            eDate.fromDate(endDate);
            request.setStartDate(sDate);
            request.setEndDate(eDate);
        }
        try {
            return await this.rfqService.listRFQ(request, {});
        } catch (err) {
            throw err
        }
    }

    async GetRFQ(id: string) {
        const request = new GetRFQRequest();
        request.setRfqId(id);
        try {
            return await this.rfqService.getRFQ(request, {});
        } catch (err) {
            throw err
        }
    }
    
}