


































































































































import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/dark.css";
import flatPickr from 'vue-flatpickr-component';
import { configNoMinDate } from "../../../src/components/flatPickrUtils";
import { required, minLength, between } from 'vuelidate/lib/validators';
import ObjectManager from '../../services/object_manager';
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import VueGridLayout from 'vue-grid-layout';
import RFQService from '../../services/rfq';
import { convertDateTime } from '../../services/protoUtils';
import { getToday, getTomorrow } from "../../../src/components/stringUtils";
import {RFQStatus, RFQSide} from "../../model/RFQModel";
import Modal from '@/components/Modal.vue';
import ModalRfq from '../components/ModalRfq.vue';
import {CryptoCode, MoneyCode} from '../../model/Regional';
import numeral from "numeral";

export default {
    components: {
      AgGridVue,
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
      flatPickr: flatPickr,
      Modal,
      ModalRfq: ModalRfq,
    },
    data() {
        return {
            RFQSide: RFQSide,
            errorMessages: [],
            gridOptions: {} as any,
            gridApi: {} as any,
            columnDefs: {},
            rowGroupPanelShow: {},
            pivotPanelShow: {},
            asyncTransactionWaitMillis: {},
            getRowNodeId: {},
            defaultColDef: {},
            autoGroupColumnDef: {},
            detailCellRendererParams: {},
            sideBar: {},
            globalRowData: [] as [],
            height: '600px',
            width: '100%',
            layout: [
                {"x":0,"y":0,"w":12,"h":15.7,"i":"0"},
                // {"x":0,"y":0,"w":2,"h":4,"i":"1"},
                // {"x":2,"y":0,"w":2,"h":5,"i":"2"},
                // {"x":4,"y":0,"w":2,"h":3,"i":"3"},

            ],
            dashboardName:"rfq-livemonitor" as string,
            rfqService: {} as RFQService,
            configNoMinDate,
            searchRFQDisabled: false as Boolean,
            defaultStartDate: getToday() as Date,
            defaultEndDate: getTomorrow() as Date,
            startDate: getToday() as Date,
            endDate: getTomorrow() as Date,
            modals: {
                modalRFQ: {
                    data: {
                        rfq: {
                            party: {} as any,
                            product: {} as any,
                            quote: {
                                result: {
                                    
                                } as any,
                            } as any,
                            order: {

                            } as any,
                            counterparty: {
                                crypto: {
                                    cryptoNetwork: {} as any,
                                } as any,
                            } as any,
                        } as any
                    } as any,
                    open: false,
                }
            },
        }
    },
    directives: {
    },
    beforeMount() {
        this.gridOptions = {
            animateRows: true,
            onSortChanged: (event: any) => this.saveState(),
            onColumnRowGroupChanged: (event: any) => this.saveState(),
            onColumnResized: (event: any) => this.saveState(),
            onRowDoubleClicked: this.onRowDoubleClickedHandler,
        };
        this.columnDefs = [
        {
            headerName: 'RFQ #',
            field: 'id',
            enablePivot: true,
            hide: false,
            rowGroup: false,
            //cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Client Code',
            field: 'client_code',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
        },
        {
            headerName: 'Counterparty Address',
            field: 'counterparty_address',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
        },
        {
            headerName: 'Client Side',
            field: 'client_side',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: ['badge','badge-grid'],
            cellClassRules: {
                'badge-success': 'x == "BUY"',
                'badge-danger': 'x == "SELL"',
            },
        },
        {
            headerName: 'Product',
            field: 'product',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
        },
        {
            headerName: 'Status',
            field: 'status',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: ['badge','badge-grid'],
            cellStyle: {'text-align': 'center'},
            cellClassRules: {
                'badge-success': 'x == "FULFILLED"',
                'badge-info': 'x == "QUOTED"',
                'badge-danger': 'x == "REJECTED"',
                'badge-warning': 'x == "CANCELLED"',
                'badge-default': 'x == "SETTLEMENT"',
                'badge-primary': 'x == "PENDING"',
            },
        },
        {
            headerName: 'Underlying',
            field: "underlying_symbol",
            enableRowGroup: true,
            enablePivot: true,
            hide: false
        },
        {
            headerName: 'Quantity',
            field: 'quantity',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: 'number',
        },
        {
            headerName: 'Quote',
            field: 'quote',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: 'number',
        },
        {
            headerName: 'Rebate',
            field: 'rebate',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: 'number',
            valueFormatter: this.numberPercentageFormatter,
        },
        {
            headerName: 'Slippage',
            field: 'slippage',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: 'number',
            valueFormatter: this.numberPercentageFormatter,
        },
        {
            headerName: 'Indicative',
            field: 'is_indicative',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            cellClass: ['badge','badge-grid'],
            cellClassRules: {
                'badge-success': 'x == "YES"',
                'badge-primary': 'x == "NO"',
            },
        },
        {
            headerName: 'Create Time',
            field: 'create_time',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            comparator: this.dateTimeComparator,
        },
        {
            headerName: 'Reply Time',
            field: 'reply_time',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            comparator: this.dateTimeComparator,
        },
        {
            headerName: 'Expiry Time',
            field: 'expiry_time',
            aggFunc: 'sum',
            enableValue: true,
            enableRowGroup: true,
            comparator: this.dateTimeComparator,
            //valueFormatter: this.numberCellFormatter,
            //cellRenderer: 'agAnimateShowChangeCellRenderer',
            // cellClassRules: {
            //     'fx-positive': 'x > 1000',
            //     'fx-null': 'x === null',
            //     'fx-negative': 'x < 1000'
            // }
        },
        {
            headerName: 'Last Updated Time',
            field: 'last_update_time',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            comparator: this.dateTimeComparator,
        },
        ];
        this.rowGroupPanelShow = 'always';
        this.pivotPanelShow = 'always';
        this.asyncTransactionWaitMillis = 100;
        this.getRowNodeId = (data) => {
            return data.id;
        };
        this.defaultColDef = {
            flex:1,
            width: 120,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filterParams: {newRowsAction: 'keep'},
            filter: true,
            enableCellChangeFlash: true,
            autoHeight: true,
        };
        this.autoGroupColumnDef = { width: 250 };
        this.detailCellRendererParams = {
            refreshStrategy: 'rows',
            template: (params) => {
                return (
                    '<div class="ag-details-row ag-details-row-fixed-height">' +
                    '<div style="padding: 4px; font-weight: bold;">' +
                    params.data.name +
                    ' ' +
                    params.data.calls +
                    ' calls</div>' +
                    '<div ref="eDetailGrid" class="ag-details-grid ag-details-grid-fixed-height"/>' +
                    '</div>'
                );
            },
        }
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: false,
                        suppressValues: true,
                    }
                }
            ],
            position: 'left',
            hiddenByDefault: false,
        };
    },
    // detailGridOptions: {
    //     rowSelection: 'multiple',
    //     enableCellChangeFlash: true,
    //     immutableData: true,
    //     getRowNodeId: function (data) {
    //       return data.callId;
    //     }
    // },
    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        this.restoreState();
        this.$root.bus.$on('enableRFQSearch', (res) => {
            this.searchRFQDisabled = false;
        });
    },
    created() {
        this.rfqService = new RFQService();
    },
    beforeDestroy() {
        this.$root.bus.$off('rfqCreatedEvent');
        this.$root.bus.$off('rfqUpdatedEvent');
        this.saveState();
    },
    destroyed() {
        this.gridApi.destroy();
    },
    computed: {
        searchRFQDisabledComputed: function() {
            return this.searchRFQDisabled;
        }
    },
    methods: {
        changeStartDate(e: string) {
            this.startDate = new Date(e);
        },
        changeEndDate(e: string) {
            this.endDate = new Date(e);
        },
        numberPercentageFormatter(params: any) {
            if (params.value != undefined) {
                return `${numeral(params.value).format('0.00 %')}`
            }
            return params.value;
        },
        async openModal(rfq_id:string) {
            let res = this.rfqService.GetRFQ(rfq_id);
            await res.then(response  => {
                this.modals.modalRFQ.data.rfq = response.toObject().rfq;
                this.modals.modalRFQ.open = true;
            },
                error => {
                    
                }
            );
        },
        async closeModal() {
            this.modals.modalRFQ.open = false;
        },
        startFeed() {
            var createRFQEntry = this.createRFQEntry;
            var globalData = this.globalRowData;
            var resultCb = this.resultCallback;
            var toast = this.$toast;
            this.$root.bus.$on('rfqCreatedEvent', (res) => {
                try {
                    if (res.notification) {
                        let rfq: any = res.notification.rfq;
                        let newItem: any = createRFQEntry(rfq);
                        globalData.push(newItem);
                        this.gridApi.applyTransactionAsync({ add: [newItem] }, resultCb);
                        toast.info("New RFQ Requested\n" + moment().format('DD/MM/YYYY hh:mm:ss'), {
                            position: "bottom-right",
                            timeout: 2000,
                            icon: true,
                            rtl: false
                        });
                    }
                } catch (except) {
                    toast.error("Error rfqCreatedEvent", {
                        position: "bottom-right",
                        timeout: 5000,
                        icon: true,
                        rtl: false
                    });
                }
            });
            this.$root.bus.$on('rfqUpdatedEvent', (res) => {
                try {
                    if (res.notification) {
                        let rfq: any = res.notification.rfq;
                        let newItem: any = createRFQEntry(rfq);
                        globalData.push(newItem);
                        this.gridApi.applyTransactionAsync({ update: [newItem] }, resultCb);
                    }
                } catch (except) {
                    toast.error("Error rfqUpdatedEvent", {
                        position: "bottom-right",
                        timeout: 5000,
                        icon: true,
                        rtl: false
                    });
                }
            });
            this.$root.bus.$on('streamRecovery', (res) => {
                this.createRowData();
            });
            this.$root.bus.$on('listRFQ', (res) => {
                this.createRowData();
                this.$root.bus.$emit('enableRFQSearch', true);
            });
        },
        searchRFQ() {
            this.searchRFQDisabled = true;
            this.$root.bus.$emit('listRFQ', true);
        },
        createRowData(api) {
            this.globalRowData = [];
            let res: any = this.rfqService.ListRFQ(this.startDate, this.endDate);
            res.then(
                response => {
                let object_response: any = response.toObject().rfqList;
                for (var i = 0; i < object_response.length; i++) {
                    let object: any = object_response[i];
                    let item = this.createRFQEntry(object);
                    this.globalRowData.push(item);
                }
                this.gridApi.setRowData(this.globalRowData);
                },
                error => {
                }
            );
        },
        copyObject(object: any) {
            var newObject: any = {};
            Object.keys(object).forEach(function (key) {
                newObject[key] = object[key];
            });
            return newObject;
        },
        buildProduct(object: any) {
            let name = "-";
            let underlying = "-";
            let quantityIsMoney = false;
            if (object != undefined) {
                if (object.cryptoSwap != undefined) {
                    name = "CRYPTO_SWAP";
                    underlying = `${CryptoCode[object.cryptoSwap.baseCurrency]}/${CryptoCode[object.cryptoSwap.quoteCurrency]}`
                }
                if (object.cryptoFiatSwap != undefined) {
                    name = "CRYPTO_FIAT_SWAP";
                    underlying = `${CryptoCode[object.cryptoFiatSwap.baseCurrency]}/${MoneyCode[object.cryptoFiatSwap.quoteCurrency]}`
                }
                if (object.fiatCryptoSwap != undefined) {
                    name = "FIAT_CRYPTO_SWAP";
                    underlying = `${MoneyCode[object.fiatCryptoSwap.baseCurrency]}/${CryptoCode[object.fiatCryptoSwap.quoteCurrency]}`
                    quantityIsMoney = true;
                }
                if (object.cryptoMintNft != undefined) {
                    name = "CRYPTO_MINT_NFT";
                    underlying = `${CryptoCode[object.cryptoMintNft.baseCurrency]}/NFT`
                }
            }
            var product = {
                name: name,
                underlying: underlying,
                quantityIsMoney: quantityIsMoney,
            };
            return product
        },
        buildQuote(object:any) {
            let value: any = "-";
            let isMoney:boolean = false;
            if (object.result.crypto != undefined) {
                value = object.result.crypto.value;
            }
            if (object.result.cash != undefined) {
                value = object.result.cash.value;
                isMoney = true;
            }
            var quote = {
                value: value,
                isMoney: isMoney
            };
            return quote;
        },
        createRFQEntry(object: any) {
            let product:any = this.buildProduct(object.product);
            let quote:any = this.buildQuote(object.quote);
            var rfq = {
                id: object.id,
                product: product.name,
                underlying_symbol: product.underlying,
                quantity: product.quantityIsMoney ? `$ ${object.neededQuantity}` :`C ${object.neededQuantity}` ,
                symbol: object.symbol,
                reply_time: object.replyTime ? convertDateTime(object.replyTime) : '-',
                expiry_time: object.expiryTime ? convertDateTime(object.expiryTime) : '-',
                create_time: object.createTime ? convertDateTime(object.createTime) : "-",
                last_update_time: object.lastUpdateTime ? convertDateTime(object.lastUpdateTime) : "-",
                status: RFQStatus[object.status],
                client_code: object.party.id,
                is_indicative: object.isIndicative ? "YES" : "NO",
                rebate: object.product ? object.product.rebate : "-",
                slippage: object.product ? object.product.slippage : "-",
                client_side: object.product ? (object.product.side == RFQSide.BUY ? "BUY" : "SELL") : "-",
                counterparty_address : object.counterparty && object.counterparty.crypto && object.counterparty.crypto.address ? object.counterparty.crypto.address : "-",
                quote: quote.isMoney ? `$ ${quote.value}` : `C ${quote.value}`
            };
            return rfq;
        },
        numberCellFormatter(params) {
            let sansDec = params.value.toFixed(2);
            let formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return `${formatted}`;
        },
        setWidthAndHeight(width, height) {
            this.width = `${width}px`;
            this.height = `${height}px`;
        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx) {
            let adjust = -20;
            newHPx += adjust;
            newWPx += 1;
            if (i == 0) {
                this.setWidthAndHeight(newWPx, newHPx);
            }
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            let adjust = -20;
            newHPx += adjust;
            newWPx += 1;
            if (i == 0) {
                this.setWidthAndHeight(newWPx, newHPx);
            }
        },
        onFlushTransactions() {
            this.gridApi.flushAsyncTransactions();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.createRowData();
            this.startFeed();
        },
        saveState() {
            ObjectManager.setSetting(this.dashboardName, this.gridColumnApi.getColumnState());
        },
        restoreState() {
            if (!ObjectManager.getSetting(this.dashboardName)) {
                return;
            }
            this.gridColumnApi.applyColumnState({
                state: ObjectManager.getSetting(this.dashboardName),
                applyOrder: true,
            });
        },
        onRowDoubleClickedHandler(row) {
            this.openModal(row.data.id);
        }
    }
};
        
